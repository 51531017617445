import React from "react";
import { Element } from "react-scroll";
import { useAppContext } from "../appContext"; // adjust the path as needed
import { Title } from "./globalStyledComponents";
import { Col, Container, Row } from "react-bootstrap";

import styled from "styled-components";


const educationData = [
  {
    id: "phd",
    institution: "Department of Electrical and Computer Engineering, Democritus University of Thrace",
    degree: "PhD in Privacy-Preserving Computations and Machine Learning over Decentralized Data",
    period: "11.2020 - 05.2024",
    description: "Focused on Federated Learning, Privacy-Enhancing Technologies, Sustainable AI, Machine Unlearning.",
  },
  {
    id: "msc",
    institution: "Department of Information and Communication Systems Engineering, University of the Aegean",
    degree: "MSc in Information and Communication Systems Security",
    period: "10.2018 - 03.2020",
    description: "Specialized in Machine Learning Security.",
  },
  {
    id: "bsc",
    institution: "Department of Informatics, Ionian University",
    degree: "BSc in Informatics",
    period: "10.2013 - 09.2017",
    description: "Focused on Software development and Programming.",
  },
  // Add more education entries here
];

const EducationName = styled.h4`
  color: #2588a2;
`;

export default function Education() {
  const { theme } = useAppContext();

  return (
    <Element name="Education" id="education">
        <section className="section">
            <Container className="text-center">
                <Title>
                    <h2>Education</h2>
                    <div className="underline"></div>
                </Title>
                <Row className="mt-3 align-items-center">
                    {educationData.map(({ id, institution, degree, period, description }) => (
                        <Col xs={12} key={id} className="my-3">                    <h3>{degree}</h3>
                        <EducationName theme={theme}>{institution}</EducationName>
                        <p>{period}</p>
                        <p>{description}</p>
                    </Col>
                    ))}
                </Row>
            </Container>
        </section>
    </Element>
  );
}
