import React from "react";
import styled from "styled-components";
// Data
import { Blog } from "../data";
// Icons
import { Icon } from '@iconify/react';

const StyledSocialLinks = styled.div`
  a {
    margin: 0 1rem;
  }
`;

export default function SocialLinks() {
  //const { blog, html_url } = useSelector(selectData);
  const blog = null;
  const email_url = 'mailto:vasilisprf@gmail.com'
  const html_url = 'https://github.com/vperifan/';
  const linked_url = 'https://www.linkedin.com/in/vasileios-perifanis/';
  const skype_url = 'https://join.skype.com/invite/emMPzkArB0NG';
  const scholar_url = 'https://scholar.google.com/citations?user=V1t6u_YAAAAJ';


  return (
    <StyledSocialLinks>
      <a
        href={email_url}
        target="_blank"
        aria-label="Contact me."
        className="link-icons"
      >
        <Icon icon="ic:outline-email" width="1.2em" height="1.2em" />
      </a>
      <a
        href={html_url}
        target="_blank"
        aria-label="Check out my GitHub profile."
        className="link-icons"
      >
        <Icon icon="icomoon-free:github"/>
      </a>
      <a
        href={linked_url}
        target="_blank"
        aria-label="Check out my LinkedIn profile."
        className="link-icons"
      >
        <Icon icon="jam:linkedin-circle" width="1.2em" height="1.2em" />
      </a>
      <a
        href={skype_url}
        target="_blank"
        aria-label="Contact me on Skype."
        className="link-icons"
      >
        <Icon icon="formkit:skype" />
      </a>
      <a
        href={scholar_url}
        target="_blank"
        aria-label="Check out my Scholar profile."
        className="link-icons"
      >
        <Icon icon="academicons:google-scholar-square" />
      </a>
      {blog && (
        <a href={blog} aria-label="External link" className="link-icons" target="_blank">
          {Blog}
        </a>
      )}
    </StyledSocialLinks>
  );
}
