import React from "react";
import { useAppContext } from "../appContext";
import { Element } from "react-scroll";
import { Col, Container, Row, Card } from "react-bootstrap";
import { Title, Loading } from "./globalStyledComponents";

const awardsData = [
    {
      id: "award8",
      name: "Best Paper Award (2024)",
      description: "Received the Best Paper Award at EICC 2024 for the paper \"SFTC: Machine Unlearning via Selective Fine-tuning and Targeted Confusion\".",
    },
    {
      id: "award7",
      name: "Academic Excellence Award (2024)",
      description: "Awarded by Democritus University of Thrace for academic performance and contributions to global challenges.",
    },
    {
      id: "award6",
      name: "Distinction in NeurIPS 2023 - Machine Unlearning Challenge (2023)",
      description: "Secured sixth place among seven winners out of 1120 participating teams from 72 countries in the machine unlearning challenge organized by Google.",
    },
    {
      id: "award5",
      name: "Best Paper Award Nominee (2023)",
      description: "Nominated for Best Paper Award at FMEC 2023 for the paper \"Towards Energy-Aware Federated Traffic Prediction for Cellular Networks\".",
    },
    {
      id: "award4",
      name: "Academic Excellence Award (2023)",
      description: "Awarded by Democritus University of Thrace for academic performance and contributions to global challenges.",
    },
    {
      id: "award3",
      name: "First Place in Federated Traffic Prediction for 5G and Beyond Challenge (2022)",
      description: "Achieved first place in a federated time-series forecasting task organized by ITU.",
    },
    {
      id: "award2",
      name: "First Place in IFMBE Scientific Challenge (2022)",
      description: "Achieved first place in a machine learning task for predicting early dropouts in healthy aging applications at the IFMBE Scientific Challenge.",
    },
    {
      id: "award1",
      name: "First in Class (2020)",
      description: "Graduated first in the MSc program \"Information and Communication Systems Security\" at the University of the Aegean.",
    },
    // Add more awards here
  ];
  

export default function Awards() {
    const [mainAwards, setMainAwards] = React.useState([]);
    const { theme } = useAppContext();
  
    React.useEffect(() => {
      const tempData = awardsData.map((el) => Object.create(el));
      setMainAwards(tempData);
    }, []);
  
    return (
      <Element name="Awards" id="awards">
        <section className="section">
          <Container>
            <Container className="d-flex">
              <Title>
                <h2>Awards and Distinctions</h2>
                <div className="underline"></div>
              </Title>
            </Container>
            {mainAwards.length === 0 && (
              <Container className="d-flex">
                <Loading />
              </Container>
            )}
            {mainAwards.length !== 0 && (
              <Row xs={1} md={2} lg={3} className="g-4 justify-content-center">
                {mainAwards.map(({ id, name, description }) => (
                  <Col key={id}>
                    <Card className="h-100" bg={theme === "light" ? "light" : "dark"} text={theme === "light" ? "dark" : "light"}>
                      <Card.Body>
                        <Card.Title>{name}</Card.Title>
                        <Card.Text>{description}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
          </Container>
        </section>
      </Element>
    );
  }
