import React from 'react';
// Skills icons - https://icon-sets.iconify.design/
import { Icon } from "@iconify/react";

// Navbar Logo image (uncomment below and import your image)
// import newLogo from "./images/yourFileName"

// Projects Images (add your images to the images directory and import below)
//import Logo from "./images/nn-logo.png";

// Hero Images (add your images to the /images directory with the same names)
import HeroLight from "./images/hero-light.jpg";
import HeroDark from "./images/hero-dark.jpg";
// If you change the import names above then you need to change the export names below
export { HeroLight as Light };
export { HeroDark as Dark };

// If you change the import names above then you need to change the export names below

/* START HERE
 **************************************************************
  Add your GitHub username (string - "YourUsername") below.
*/
export const githubUsername = "vperifan";

// Navbar Logo image
export const navLogo = undefined;

// Blog link icon - https://icon-sets.iconify.design/
export const Blog = <Icon icon="ph:link-bold" />;

/* About Me
 **************************************************************
  Add a second paragraph for the about me section.
*/
export const moreInfo =
  "I am a Research Scientist specializing in Machine Learning, Federated Learning, Privacy-Enhancing Technologies and Machine Unlearning. I hold a PhD in Privacy-Preserving Machine Learning, an MSc in Computer Security and a BSc in Informatics. If you are interested in building novel AI applications, do not hesitate to contact me!";

/* Skillshesitate
 ************************************************************** 
  Add or remove skills in the SAME format below, choose icons here - https://icon-sets.iconify.design/
*/
export const skillData = [
  {
    id: 1,
    skill: <Icon icon="vscode-icons:file-type-python" className="display-4"/>,
    name: "Python",
  },	
  {
    id: 2,
    skill: <Icon icon="vscode-icons:file-type-cpp3" className="display-4"/>,
    name: "C++",
  },
  {
    id: 3,
    skill: <Icon icon="devicon:java" className="display-4"/>,
    name: "Java",
  },
  {
    id: 4,
    skill: <Icon icon="vscode-icons:file-type-kotlin" className="display-4"/>,
    name: "Kotlin",
  },
  {
    id: 5,
    skill: <Icon icon="logos:pytorch-icon" className="display-4"/>,
    name: "PyTorch",
  },
  {
    id: 6,
    skill: <Icon icon="devicon:scikitlearn" className="display-4" />,
    name: "Scikit-learn",
  },
  {
    id: 7,
    skill: <Icon icon="devicon:pandas" className="display-4" />,
    name: "Pandas",
  },
  {
    id: 8,
    skill: <Icon icon="devicon:matplotlib-wordmark" className="display-4" />,
    name: "Matplotlib",
  },
  {
    id: 9,
    skill: <Icon icon="devicon:plotly" className="display-4" />,
    name: "Plotly",
  },
  {
    id: 10,
    skill: <Icon icon="devicon:streamlit" className="display-4" />,
    name: "Streamlit",
  },
  {
    id: 11,
    skill: <Icon icon="devicon:fastapi" className="display-4" />,
    name: "FastAPI",
  },
  {
    id: 12,
    skill: <Icon icon="vscode-icons:file-type-flutter" className="display-4" />,
    name: "Flutter",
  },
  {
    id: 13,
    skill: <Icon icon="eos-icons:neural-network"  style={{color: '#3465a4'}} className="display-4" />,
    name: "Machine Learning",
  },
  {
    id: 14,
    skill: <Icon icon="flat-color-icons:privacy" className="display-4" />,
    name: "Privacy",
  },
  {
    id: 15,
    skill: <Icon icon="noto:llama" className="display-4" />,
    name: "Large Language Models",
  },
  {
    id: 16,
    skill: <Icon icon="skill-icons:docker" className="display-4" />,
    name: "Docker",
  },
  {
    id: 17,
    skill: <Icon icon="devicon:git" className="display-4" />,
    name: "Git",
  },
  {
    id: 18,
    skill: <Icon icon="skill-icons:github-dark" className="display-4" />,
    name: "GitHub",
  },
];

// Resume link (string - "https://YourResumeUrl") - I am using CloudFront to share my resume (https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/Introduction.html)
export const resume = null;

/* Projects
 ************************************************************** 
  List the repo names (string - "your-repo-name") you want to include (they will be sorted alphabetically). If empty, only the first 3 will be included.
*/


/* Contact Info
 ************************************************************** 
  Add your formspree endpoint below.
  https://formspree.io/
*/
export const formspreeUrl = "https://formspree.io/f/xoqgokwl";
