import React from "react";
import { Element } from "react-scroll";
import { useAppContext } from "../appContext"; // adjust the path as needed
import { Title } from "./globalStyledComponents";
import { Col, Container, Row } from "react-bootstrap";


import styled from "styled-components";


const experienceData = [
    {
        id: 'job7',
        company: 'Enora Innovation',
        role: 'Research Software Engineer',
        period: '03.2024 - Today',
        description: 'Designing software systems, from simple APIs to complete AI/ML apps.',
    },
    {
        id: 'job6',
        company: 'Greek Public Employement Service (DYPA)',
        role: 'Information Technology Instructor',
        period: '10.2020 - 06.2024',
        description: 'Courses: Algorithms and Data Structures, Communication Systems, Operating Systems, Databases, Web Programming, Python Programming.',
    },
    {
        id: 'job5',
        company: 'Democritus University of Thrace',
        role: 'Teaching Assistant',
        period: '03.2021 - 02.2024',
        description: 'Courses: Algorithms and Data Structures, Algorithms and Complexity, Algorithmic Aspects of Privacy (Postgraduate).',
    },
    {
        id: 'job4',
        company: 'Democritus University of Thrace',
        role: 'Researcher',
        period: '07.2023 - 11.2023',
        description: 'Lead Machine Learning and Privacy Engineer as part of the FLAMENCO project.',
    },
    {
        id: 'job3',
        company: 'Centre Tecnològic de Telecomunicacions de Catalunya (CTTC)',
        role: 'Visiting Researcher',
        period: '05.2022 - 05.2022',
        description: 'Research Software Engineer related to energy-efficient Federated Learning algorithms.',
    },
    {
        id: 'job2',
        company: 'Athena Research Center',
        role: 'Researcher',
        period: '01.2021 - 09.2021',
        description: 'Research Software Engineer related to Point of Interest Recommendation algorithms as part of the PROMOTE project.',
    },
    {
        id: 'job1',
        company: 'Ministry of Tourism',
        role: 'System Administrator',
        period: '02.2019 - 02.2020',
        description: 'Responsible for daily operational activities and lead developer of a hotels management app.',
    },
  // Add more experience entries here
];

const CompanyName = styled.h4`
  color: #2588a2;
`;

export default function Experience() {
  const { theme } = useAppContext();

  return (
    <Element name={"Experience"} id="experience">
        <section className="section">
            <Container className="text-center">
                <Title>
                <h2>Experience</h2>
                <div className="underline"></div>
            </Title>
            <Row className="mt-3 align-items-center">
            {experienceData.map(({ id, company, role, period, description }) => (
              <Col xs={12} key={id} className="my-3">
                  <h3>{role}</h3>
                  <CompanyName theme={theme}>{company}</CompanyName>
                  <p>{period}</p>
                  <p>{description}</p>
              </Col>
            ))}
          </Row>
          </Container>
        </section>
    </Element>
  );
}
