import React from "react";
import { Element } from "react-scroll";
import { useAppContext } from "../appContext"; // adjust the path as needed
import { Title } from "./globalStyledComponents";
import { Col, Container, Row } from "react-bootstrap";

import styled from "styled-components";


// Example publication data
const publicationData = [
  {
    id: "pub6",
    title: "SFTC: Machine Unlearning via Selective Fine-tuning and Targeted Confusion",
    authors: "Vasileios Perifanis, Efstathios Karypidis, Nikos Komodakis and Pavlos Efraimidis",
    journal: "European Interdisciplinary Cybersecurity Conference, ACM",
    year: 2024,
    description: "10.1145/3655693.3655697",
  },
  {
    id: "pub5",
    title: "Towards Energy-Aware Federated Traffic Prediction for Cellular Networks",
    authors: "Vasileios Perifanis, Nikolaos Pavlidis, Selim F Yilmaz, Francesc Wilhelmi, Elia Guerra, Marco Miozzo, Pavlos Efraimidis, Paolo Dini and Remous-Aris Koutsiamanis",
    journal: "2023 Eighth International Conference on Fog and Mobile Edge Computing (FMEC), IEEE",
    year: 2023,
    description: "10.1109/FMEC59375.2023.10306017",
  },
  {
    id: "pub4",
    title: "Federated learning for 5G base station traffic forecasting",
    authors: "Vasileios Perifanis, Nikolaos Pavlidis, Remous-Aris Koutsiamanis and Pavlos Efraimidis",
    journal: "Computer Networks, Elsevier",
    year: 2023,
    description: "10.1016/j.comnet.2023.109950",
  },
  {
    id: "pub3",
    title: "FedPOIRec: Privacy-Preserving Federated POI Recommendation with Social Influence",
    authors: "Vasileios Perifanis, George Drosatos, Giorgos Stamatelatos and Pavlos Efraimidis",
    journal: "Information Sciences, Elsevier",
    year: 2023,
    description: "10.1016/j.ins.2022.12.024",
  },
  //TODO
  {
    id: "pub1",
    title: "Federated Neural Collaborative Filtering",
    authors: "Vasileios Perifanis and Pavlos Efraimidis",
    journal: "Knowledge-Based Systems, Elsevier",
    year: 2022,
    description: "10.1016/j.knosys.2022.108441",
  },
  // Add more publication entries here
];

const PublicationTitle = styled.h4`
  text-align: left;
`;

const PublicationText = styled.p`
  text-align: left;
`;

export default function Publication() {
  const { theme } = useAppContext();

  return (
    <Element name={"Publication"} id="publication">
      <section className="section">
        <Container className="text-center">
          <Title>
            <h2>Selected Publications</h2>
            <div className="underline"></div>
          </Title>
          <Row className="mt-3 align-items-start">
            {publicationData.map(({ id, title, authors, journal, year, description }) => (
              <Col xs={12} key={id} className="my-3">
                <PublicationTitle theme={theme}>{title}</PublicationTitle>
                <PublicationText>{authors}</PublicationText>
                <PublicationText><em>{journal}</em>, {year}</PublicationText>
                <PublicationText>DOI: <a href={description} target="_blank">{description}</a></PublicationText>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </Element>
  );
}