import React from "react";
import { useAppContext } from "../appContext";
import { Link } from "react-router-dom";
import { Element } from "react-scroll";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Title, Loading } from "./globalStyledComponents";
import StyledCard from "./StyledCard";
import promoteImg from "../images/promote.png";
import flamencoImg from "../images/flamenco.png"
import oahImg from "../images/OneAquaHealth.svg"

const customProjects = [
  {
    id: "flamenco",
    image: oahImg,
    name: "OneAquaHealth (2024-2026)",
    description: "Protecting urban aquatic ecosystems to promote One Health",
    homepage: "https://www.oneaquahealth.eu/",
  },
  {
    id: "flamenco",
    image: flamencoImg,
    name: "FLAMENCO (2023)",
    description: "Privacy-Preserving Federated Learning Application for diagnosis of coMmunication disordErs iN Child development",
    homepage: "https://github.com/nikopavl4/FLAMENCO-Project",
  },
  {
    id: "promote",
    image: promoteImg,
    name: "PROMOTE (2021)",
    description: "Tools for Promoting Tourism Experience",
    homepage: "https://promote.athenarc.gr/",
  },
  // Add more custom projects here
];


export default function Projects() {
  const [mainProjects, setMainProjects] = React.useState([]);
  const { theme } = useAppContext();

  React.useEffect(() => {
    const tempData = customProjects.map((el) => Object.create(el));
    setMainProjects([...tempData.slice(0, 3)]);
  }, []);

  return (
    <Element name="Projects" id="projects">
      <section className="section">
        <Container>
          <Container className="d-flex">
            <Title>
              <h2>Projects</h2>
              <div className="underline"></div>
            </Title>
          </Container>
          {mainProjects.length === 0 && (
            <Container className="d-flex">
              <Loading />
            </Container>
          )}
          {mainProjects.length !== 0 && (
            <>
              <Row xs={1} md={2} lg={3} className="g-4 justify-content-center">
                {mainProjects.map(({ id, image, name, description, homepage }) => (
                  <Col key={id}>
                    <StyledCard
                      image={image}
                      name={name}
                      description={description}
                      homepage={homepage}
                    />
                  </Col>
                ))}
              </Row>
              {customProjects.length > 3 && (
                <Container className="text-center mt-5">
                  <Link to="/All-Projects">
                    <Button
                      size="lg"
                      variant={
                        theme === "light" ? "outline-dark" : "outline-light"
                      }
                    >
                      All Projects
                    </Button>
                  </Link>
                </Container>
              )}
            </>
          )}
        </Container>
      </section>
    </Element>
  );
}
